/* wizard.css */
.wizard-container {
  max-width: 600px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.wizard-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wizard-button {
  padding: 10px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

.wizard-button:hover {
  background-color: #0056b3;
}

.wizard-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
